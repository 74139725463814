.sideNavOpen {
     height: 100%;
     width: 240px;
     margin-top: 55px;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 2000;
     overflow-x: hidden;
     font-size: 16px;
     padding-top: 40px;
     transition: 0.1s;
     background-color: #ffffff;
     box-shadow: 0 0 8px 0 #e672401e
 }
 .sideNavClose {
     height: 100%;
     width: 0px;
     position: fixed;
     top: 0;
     font-size: 20px;
     left: 0;
     z-index: 10;
     overflow-x: hidden;
     transition: 0.1s;
     background-color: #161616;
     box-shadow: 0 0 8px 0 #e672401e;
 }
 .ContentOverlayOpen {
     display: block;
     position: fixed;
     height: 100%;
     top: 0;
     left: 0;
     z-index: 7;
     right: 0;
     bottom: 0;
     cursor: pointer;
     background-color: rgba(0,0,0,0.5);
 }
 .ContentOverlayClose {
     display: none;
 }