.nav-logo{
     width: 180px;
     height: 40px;
 }
 .cart-btn{
     padding: 5px 15px 5px 15px;
     color: #ffffff;
     border-radius: 20px !important;
     border:0.5px solid #C24914;
     background: #C24914 !important;
     text-decoration: none;
 }
 .navbar { 
     font-size: 16px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
     background-color: #ffffff !important;
     box-shadow: 0 0 8px 0 #e672401e
 }

 .TopSectionDown{
      margin-bottom: 80px;
 }