.section-title {
     margin-top: 50px;
     margin-bottom: 50px;
     color: #000000;
     font-size: 20px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
 }
.section-title-contact {
     color: #000000;
     font-size: 18px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
 }
.section-title-login {
     margin-top: 50px;
     margin-bottom: 10px;
     color: #000000;
     font-size: 30px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 600;
 }
 .section-sub-title {
     color: #212121;
     font-size: 15px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 300;
 }
 .product-name-on-card {
     color: #ffffff;
     font-size: 16px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
 }
 .product-price-on-card {
     color: #FC8621;
     font-size: 14px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 600;
 }
 .category-name{
     color: #000000;
     font-size: 15px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 600;
 }
 .text-link{
    color: inherit;
    text-decoration: inherit;
 }
 .breadbody{
    background-color: #e6e6e6;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-weight: 600;
    border-radius: 10px;
 }
 .userprofile{
    width: 200px;
    height: 200px;
    margin-left: 80px;
}