@media (max-width: 374.98px) {
  .slider-img {
  width: 100% !important;
  margin-top: 60px;
  height: 175px;
}
.breadbody{
    background-color: #e6e6e6;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 130px;
 }
  .Mobile {
  }
  .Desktop {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 575.98px) {
  .slider-img {
  width: 100% !important;
  margin-top: 60px;
  height: 175px;
}
.breadbody{
    background-color: #e6e6e6;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 130px;
 }
  .Mobile {
  }
  .Desktop {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .slider-img {
  width: 100% !important;
  margin-top: 60px;
  height: 175px;
}
.breadbody{
    background-color: #e6e6e6;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 130px;
 }
  .Mobile {
  }
  .Desktop {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .Mobile {
    display: none;
  }
  .Desktop {
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .Mobile {
    display: none;
  }
  .Desktop {
  }
}

@media (min-width: 1200px) {
  .Mobile {
    display: none;
  }
  .Desktop {
  }
}
