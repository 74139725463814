.card {
  width: 100% !important;
  text-decoration: none;
  border: none !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}
.card:hover {
  border-radius: 0.5rem !important;
  text-decoration: none;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 100% !important;
  
}
.image-box img {
  width: 200px !important;
  transition: all 0.3s;
  display: block;
  height: 200px !important;
  transform: scale(1);
}
.image-box:hover img {
  transform: scale(1.1);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.section-title h2 {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin: 0;
}

.section-title h2:before {
  position: absolute;
  top: 17px;
  left: -100px;
  width: 80px;
  height: 2px;
  content: "";
  background-color: #000;
}

.section-title h2:after {
  position: absolute;
  top: 17px;
  right: -100px;
  width: 80px;
  height: 2px;
  content: "";
  background-color: #000;
}
