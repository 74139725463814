.Product-Name{
     color: #212121;
     font-size: 20px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
 }
 .Product-price-card{
     padding: 10px;
     margin: 2px;
     border: 0.5px solid #c8c8c8;
 }

 .Review-Title{
     color: #c24914;
     font-size: 18px;
     font-family: 'Roboto Condensed', sans-serif;
     font-weight: 400;
 }
 .fa-star{
  color: #FF9529;
 }
 .bigimage{
    height: 530px;
 }
 .smallimage{
    height: 132.5px;
    width: 132.5px;
 }
 .product-sm-img{
    transition: 0.1s;
 }
 .product-sm-img:hover{
    cursor: pointer;
    height: 130px;
    width: 130px;
    margin: 1.5px;
 }
 .detailimage{
   height: 530px;
   margin: 0px;
 }