@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto+Condensed:wght@300;400;700&display=swap");

@import "bigSlider.css";
@import "button.css";
@import "card.css";
@import "footer.css";
@import "megaMenu.css";
@import "navMenuDesktop.css";
@import "navMenuMobile.css";
@import "typo.css";
@import "megaMenuMobile.css";
@import "responsive.css";
@import "megaMenuAll.css";
@import "onboard.css";
@import "common.css";
@import "productDetails.css";
@import "notification.css";
@import "cartList.css";

body {
  background-color: #f9e0ae00;
}
/* #f9e0ae5e */
